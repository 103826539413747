import React, { useState, useEffect } from "react"
import styled, { withTheme } from "styled-components"
import remcalc from "remcalc"
import breakpoint from "styled-components-breakpoint"
import is from "styled-is"

let Animate
if (typeof window !== `undefined`) {
  const animateLib = require("@oframe/animate")
  Animate = animateLib.Animate
}

const LargeTitleContainer = styled.h1`
  font-size: 34px;
  margin-top: 0.23em;
  margin-bottom: 0.23em;
  line-height: 1.25;
  overflow: hidden;
  font-weight: 900;
  color: ${props => (props.color ? props.color : "inherit")};
  transform: translate3d(0, 0, 0);

  ${is("large")`
    font-size: 11vw;
    text-align: center;
  `};

  ${is("small")`
    font-weight: 400;
    font-size: 20px;
    line-height: 1.35;
  `};

  ${is("alignRight")`
    text-align: left;

    ${breakpoint("m")`
      text-align: right;
    `};
  `};

  span {
    will-change: transform;
    transition: ${props =>
      `transform ${props.theme.durations.xxlong}ms ${
        props.theme.easings.out.value
      } ${props.delay || 0}ms`};
    transform: translate3d(0, ${props => `${props.show ? 0 : 100}%`}, 0);
  }

  a:hover,
  a:focus,
  a:active,
  a {
    color: inherit;
  }

  ${is("gutter")`
    margin-bottom: ${remcalc(20)};
  `};

  ${breakpoint("s")`
    font-size: 8vw;

    ${is("large")`
      font-size: 11vw;
    `};
    ${is("small")`
        font-size: 3vw;
    `};
  `}

  ${breakpoint("m")`
    font-size: 7vw;
    ${is("large")`
      font-size: 112px;
    `};
    ${is("small")`
        font-size: 3vw;
    `};
  `};

  ${breakpoint("xl")`
    font-size: ${remcalc(90)};

    ${is("large")`
      font-size: 7vw;
    `};
    ${is("small")`
        font-size: ${remcalc(22)};
    `};
  `};
`

const Text = styled.span`
  display: block;
  will-change: transform;
`

const SlidingText = ({
  children,
  show = true,
  delay = 0,
  gutter = false,
  small = false,
  large = false,
  alignRight = false,
  color,
}) => {
  const [onceShow, setOnceShow] = useState(show)
  useEffect(() => {
    if (!show) {
      return
    }
    setOnceShow(show)
  }, [show])

  return (
    <LargeTitleContainer
      color={color}
      show={onceShow}
      delay={delay}
      gutter={gutter}
      small={small}
      large={large}
      alignRight={alignRight}
    >
      <Text>{children}</Text>
    </LargeTitleContainer>
  )
}
export default withTheme(SlidingText)
