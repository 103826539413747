import { Link } from "gatsby"
import styled from "styled-components"
import React, { useRef, useState } from "react"
import breakpoint from "styled-components-breakpoint"
import SlidingText from "./slidingText"
import AppearingText from "./appearingText"
import useIntersectionObserver from "../hooks/useIntersectionObserver"

const text = [
  "Brew some tea,",
  "kick back",
  "and enjoy the world.",
  "Beats to listen to when you stretch, when you walk the dog, run in the nature or just breathe in and out next to your friends and family.",
]

const Wrapper = styled.div`
  position: absolute;
  padding: 4vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;

  ${breakpoint("m")`
    flex-direction: row;
  `};
`

const Col = styled.div`
  flex: ${props => props.flex};
  display: flex;
  flex-direction: column;

  &:first-child {
    justify-content: flex-end;
  }

  &:nth-child(2) {
    justify-content: center;
    padding-bottom: 0;
  }

  ${breakpoint("m")`
    &:first-child {
      justify-content: center;
    }
    &:nth-child(2) {
    justify-content: flex-end;
    padding-bottom: 6vw;
    }
  `};
`

const Description = ({ onIntersect }) => {
  const ref = useRef(null)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const onIOIntersect = data => {
    setIsIntersecting(data[0].isIntersecting)
  }
  useIntersectionObserver({
    target: ref,
    onIntersect: onIOIntersect,
    threshold: 0.7,
  })
  useIntersectionObserver({
    target: ref,
    onIntersect: onIntersect,
    threshold: 0.05,
  })

  return (
    <Wrapper ref={ref}>
      <Col flex={2}>
        <SlidingText gutter show={isIntersecting}>
          {text[0]}
        </SlidingText>
        <SlidingText gutter show={isIntersecting} delay={600}>
          {text[1]}
        </SlidingText>
        <SlidingText gutter show={isIntersecting} delay={1200}>
          {text[2]}
        </SlidingText>
      </Col>
      <Col flex={1}>
        <AppearingText alignRight small show={isIntersecting} delay={2000}>
          {text[3]}
        </AppearingText>
      </Col>
    </Wrapper>
  )
}

export default Description
