import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GL from "../components/gl"
import GL2 from "../components/gl2"
import GL3 from "../components/gl3"

const IndexPage = () => {
  const [ready, setReady] = useState(false)
  const [isIos, setIsIos] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [setReady, ready])

  useEffect(() => {
    setIsIos(/iphone|ipad|ipod/i.test(window.navigator.userAgent))
  }, [])

  if (!ready) {
    return <SEO title="Home" />
  }
  return (
    <Layout>
      <SEO title="Home" />
      <GL isIos={isIos} />
      <GL2 isIos={isIos} />
      <GL3 isIos={isIos} />
    </Layout>
  )
}

export default IndexPage
