import React, { useState, useEffect } from "react"
import styled, { withTheme } from "styled-components"
import remcalc from "remcalc"
import breakpoint from "styled-components-breakpoint"
import is from "styled-is"

let Animate
if (typeof window !== `undefined`) {
  const animateLib = require("@oframe/animate")
  Animate = animateLib.Animate
}

const Container = styled.h1`
  font-size: 34px;
  margin-top: 0.23em;
  margin-bottom: 0.23em;
  line-height: 1.2;
  overflow: hidden;
  font-weight: 900;
  color: ${props => (props.color ? props.color : "inherit")};

  ${is("small")`
    font-weight: 400;
    font-size: 20px;
    line-height: 1.35;
  `};

  ${is("large")`
    font-size: 11vw;
    text-align: center;
  `};

  ${is("alignRight")`
    text-align: left;

    ${breakpoint("m")`
      text-align: right;
    `};
  `};

  span {
    opacity: ${props => `${props.progress}`};
  }

  span {
    will-change: opacity;
    transition: ${props =>
      `opacity ${props.theme.durations.xxlong}ms ${
        props.theme.easings.out.value
      } ${props.delay || 0}ms`};
    opacity: ${props => `${props.show ? 1 : 0}`};
  }

  ${is("gutter")`
    margin-bottom: ${remcalc(20)};
  `};

  ${breakpoint("s")`
    font-size: 8vw;
    ${is("small")`
        font-size: 3vw;
    `};
    ${is("large")`
      font-size: 11vw;
    `};
  `}

  ${breakpoint("m")`
    font-size: 7vw;
    ${is("small")`
        font-size: 3vw;
    `};
    ${is("large")`
      font-size: 112px;
    `};
  `};

  ${breakpoint("xl")`
    font-size: ${remcalc(90)};

    ${is("small")`
        font-size: ${remcalc(22)};
    `};

    ${is("large")`
      font-size: 7vw;
    `};
  `};
`

const Text = styled.span`
  display: block;
  will-change: transform;
`

const AppearingText = ({
  children,
  show = true,
  delay = 0,
  gutter = false,
  small = false,
  alignRight = false,
  color,
}) => {
  const [onceShow, setOnceShow] = useState(show)
  useEffect(() => {
    if (!show) {
      return
    }
    setOnceShow(show)
  }, [show])

  return (
    <Container
      show={onceShow}
      delay={delay}
      gutter={gutter}
      small={small}
      alignRight={alignRight}
      color={color}
    >
      <Text>{children}</Text>
    </Container>
  )
}
export default withTheme(AppearingText)
