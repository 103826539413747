import { useState, useEffect } from "react"

export default (threshold = 0) => {
  const [dimensions, setDimension] = useState({
    width: 0,
    height: 0,
  })
  let initialHeight = 0
  let initialWidth = 0

  useEffect(() => {
    const handler = () => {
      const { innerWidth, innerHeight } = window
      if (!threshold) {
        setDimension({ width: innerWidth, height: innerHeight })
        return
      }

      if (
        Math.abs(innerWidth - initialWidth) > threshold ||
        Math.abs(innerHeight - initialHeight) > threshold
      ) {
        initialHeight = window.innerHeight
        setDimension({ width: innerWidth, height: innerHeight })
      }
    }

    initialHeight = window.innerHeight
    initialWidth = window.innerWidth
    setDimension({ width: window.innerWidth, height: window.innerHeight })
    window.addEventListener("resize", handler)

    return () => window.removeEventListener("resize", handler)
  }, [])

  return {
    width: dimensions.width,
    height: dimensions.height,
  }
}
