import React, { useState, useEffect } from "react"
import styled, { withTheme } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import is from "styled-is"

const Container = styled.footer`
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  padding: ${props => `${props.theme.space[4]}`};
  flex-direction: column;

  ${breakpoint("s")`
    justify-content: space-between;
    flex-direction: row;
  `}
`

const A = styled.a`
  margin: ${props => `0 ${props.theme.space[1]}`};
  transition: ${props =>
    `color ${props.theme.durations.fast}ms ${props.theme.easings.inOut.value}`};
  color: inherit;

  &:focus,
  &:active,
  &:hover {
    color: ${props => props.theme.colors.lightBg};
  }
`

const Footer = ({}) => {
  return (
    <Container>
      <div>
        <A
          target="_blank"
          rel="noopener noreferrer"
          href="https://open.spotify.com/artist/1Q2lebZd9A7fFI1pMat3aP?si=Fcrq14bPSI2Zd5K7xKfbgQ"
        >
          Spotify
        </A>{" "}
        /{" "}
        <A
          target="_blank"
          rel="noopener noreferrer"
          href="https://soundcloud.com/ant-nio-capelo"
        >
          Soundcloud
        </A>{" "}
        /{" "}
        <A
          target="_blank"
          rel="noopener noreferrer"
          href="https://stereotipo.bandcamp.com"
        >
          Bandcamp
        </A>
      </div>
      <div>
        made by
        <A target="_blank" rel="noopener noreferrer" href="https://capelo.me">
          Capelo
        </A>
      </div>
    </Container>
  )
}
export default withTheme(Footer)
