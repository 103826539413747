import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import React, { useRef, useState } from "react"
import AppearingText from "./appearingText"
import useIntersectionObserver from "../hooks/useIntersectionObserver"

const Wrapper = styled.div`
  border: ${props => `${props.theme.space[5]} solid #a2c5a7`};
  mix-blend-mode: normal;
  border-width: ${props => props.theme.space[4]};

  @supports (mix-blend-mode: screen) {
    & {
      mix-blend-mode: screen;
      border-color: black;
    }
  }

  ${breakpoint("s")`
    border-width: ${props => props.theme.space[5]};
  `}

  background: white;
  position: absolute;
  padding: 4vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 8px;
  z-index: 1;
  display: flex;
  flex-direction: column;
`

const Col = styled.div`
  flex: ${props => props.flex};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const A = styled.a`
  padding: 10px;
  text-align: center;
  border: 3px solid;
  display: inline-block;
  border-color: transparent;
  transition: color 0.5s ease;
  color: inherit;

  &:focus,
  &:active,
  &:hover {
    color: #3f3e03;
  }
`

const Links = ({ onIntersect }) => {
  const ref = useRef(null)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const onIOIntersect = data => {
    setIsIntersecting(data[0].isIntersecting)
  }

  useIntersectionObserver({
    target: ref,
    onIntersect: onIOIntersect,
    threshold: 0.7,
  })

  useIntersectionObserver({
    target: ref,
    onIntersect: onIntersect,
    threshold: 0.05,
  })

  return (
    <Wrapper ref={ref}>
      <Col flex={2}>
        <AppearingText gutter show={isIntersecting} color="black" large>
          <A
            target="_blank"
            rel="noopener noreferrer"
            href="https://open.spotify.com/album/4XB37qZ1yDCRjbi7xCTYGY?si=H27W_voxRgSRVDInBRSFAg"
          >
            Listen on Spotify
          </A>
        </AppearingText>
      </Col>
    </Wrapper>
  )
}

export default Links
