import React, { Component } from "react"
import { Shaders, Node, GLSL } from "gl-react"
import { Surface } from "gl-react-dom"
import color2array from "color2array"
import { Text } from "./text"

const threshold = 100

let Animate
if (typeof window !== `undefined`) {
  const animateLib = require("@oframe/animate")
  Animate = animateLib.Animate
}

const getColor = color => {
  const b = color2array(color)
  return [b[0] / 255, b[1] / 255, b[2] / 255]
}

const shaders = Shaders.create({
  base: {
    frag: GLSL`
     #define PI 3.1415926538

      precision highp float;
      varying vec2 uv;
      uniform vec3 color;
      uniform float aspect;
      uniform float amplitude;
      uniform sampler2D t;
      uniform float radius;

      // Random fn for grain effect
      float rand(vec2 co) {
        return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
      }

      void main() {
        // bottom-left and top-right margins
        float margin = 0.10;
        vec2 bl = step(vec2(margin),uv);
        vec2 tr = step(vec2(margin),1.0-uv);
        float isInsideMargin = 1. - (bl.x * bl.y * tr.x * tr.y);

        // center point, with aspect ratio correction
        vec2 center = uv - 0.5;
        center.x *= aspect;

        // For vertical effect distortion
        vec2 distortedUv = uv;
        // distortedUv.y += cos(amplitude * (uv.x) * 100.) / 30.;
        float dist = length(center);
        float isOutsideCircle = 1. - smoothstep(radius, radius * 0.999, dist);

        distortedUv.y -= isOutsideCircle * amplitude * cos((uv.y - 1.) * PI) * sin((uv.x - 0.5) * PI * 15.) / (2200. * (uv.x - 0.5));

        // Final color will the child texture or the regular background,
        // depending if it's inside the margin 
        vec4 finalColor = mix(
          texture2D(t, distortedUv),
          vec4(color, 1.0),
          isInsideMargin
        ); 
        
        // Let's apply the grain
        float amount = 0.05;

        float diff = (rand(center) - 0.5) * amount;
        finalColor.r += diff;
        finalColor.g += diff;
        finalColor.b += diff;

        gl_FragColor= finalColor;
      }
    `,
  },
  lines: {
    frag: GLSL`
      precision highp float;
      uniform vec3 bgColor;
      varying vec2 uv;
      uniform float len;
      uniform float aspect;
      uniform sampler2D t;
      uniform float radius;

      void main() {
        vec3 color = mix(vec3(1., 1., 1.), bgColor, 1. - uv.y);
        // Let's try to use 42 horizontal lines, with a width of 0.57
        float f = fract(uv.y * 42.) * 0.57;  
        float pct = step(f, 0.5);
        pct = step(step(uv.x, len), pct);
        pct = step(step(1.-len, uv.x), pct);

        vec2 center = uv - 0.5;
        center.x *= aspect;
  
        // check if pixel is inside radius, comparing it with 
        // the distance to the center
        float dist = length(center);
  
        float insideCircle = smoothstep(radius, radius * 0.999, dist);
        vec4 middle = mix(vec4(bgColor, 1.), texture2D(t, uv), insideCircle);
        vec4 horizontalLines = mix(vec4(color, 1.0 - insideCircle), middle, pct);
  
        gl_FragColor = mix(horizontalLines, middle, insideCircle);
      }`,
  },
})

// We can make a <HelloBlue blue={0.5} /> that will render the concrete <Node/>
export class Base extends Component {
  render() {
    const { color, children: t, aspect, amplitude, radius } = this.props

    return (
      <Node
        shader={shaders.base}
        uniforms={{ t, color, aspect, amplitude, radius }}
      />
    )
  }
}

// We can make a <HelloBlue blue={0.5} /> that will render the concrete <Node/>
export class Lines extends Component {
  render() {
    const { bgColor, len, children: t, aspect, radius } = this.props
    return (
      <Node
        shader={shaders.lines}
        uniforms={{ radius, aspect, bgColor, len, t }}
      />
    )
  }
}

const initialAmp = 0.0

// Our example will pass the slider value to HelloBlue
class GL extends Component {
  state = {
    width: 0,
    height: 0,
    amplitude: initialAmp,
    len: 0,
    textReady: false,
  }
  color = "#9cbfa1"
  componentDidMount() {
    window.addEventListener("resize", this.resize)
    this.resize()

    return

    const url = "/static/beat.mp3"

    var request = new XMLHttpRequest()
    request.open("GET", url, true)
    request.responseType = "arraybuffer"

    var context = new AudioContext()

    var source = context.createBufferSource() // creates a sound source
    this.analyser = context.createAnalyser() //we create an analyser
    this.analyser.smoothingTimeConstant = 0.9
    this.analyser.fftSize = 512 //the total samples are half the fft size.
    source.loop = true

    request.onload = () => {
      context.decodeAudioData(
        request.response,
        buffer => {
          source.buffer = buffer // tell the source which sound to play
          source.connect(this.analyser)
          this.analyser.connect(context.destination)

          source.start(0) // play the source now
          analyse()
        },
        e => console.log("error...", e)
      )
      const analyse = () => {
        const array = new Uint8Array(this.analyser.fftSize)
        this.analyser.getByteTimeDomainData(array)
        let a

        let average = 0
        let max = 0
        for (let i = 0; i < array.length; i++) {
          a = Math.abs(array[i] - 128)
          average += a
          max = Math.max(max, a)
        }

        average /= array.length

        average = array[array.length - 1]
        // console.log({ average })
        this.setState({
          amplitude: max > 0 ? average / max : 0,
        })

        requestAnimationFrame(analyse)
      }
    }
    request.send()

    // navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
    //   var mediaStream = context.createMediaStreamSource(stream)
    //   var volume = document.getElementById("volume")

    //     this.value = meter.volume
    //     this.setState({ amplitude: meter.volume })
    //   })

    //   mediaStream.connect(meter)
    //   stream.onended = meter.close.bind(meter)
    // })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }
  resize = () => {
    if (!this.width) {
      this.width = window.innerWidth
      this.height = window.innerHeight
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight < 460 ? 460 : window.innerHeight,
      })
    }

    if (
      Math.abs(window.innerWidth - this.width) > threshold ||
      Math.abs(window.innerHeight - this.height) > threshold
    ) {
      this.height = window.innerHeight
      this.width = window.innerWidth
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight < 460 ? 460 : window.innerHeight,
      })
    }
  }
  getRadius = () => {
    let size = 0.36
    const { width, height } = this.state

    if (width < 1024) {
      if (width > 768) {
        size = 0.28
      } else if (width >= 460) {
        size = 0.2
      } else {
        size = 0.15
      }
    }

    if (height <= 600) {
      size = 0.18
    }
    return size
  }
  onTextReady = () => {
    this.setState({ textReady: true })
    const obj = {
      len: 0.45,
    }
    new Animate(obj, 1800, {
      len: 1,
      delay: 500,
      ease: "inOutCirc",
      update: () => {
        this.setState({ len: obj.len })
      },
    })
  }
  render() {
    const { amplitude, len, textReady } = this.state
    const { width, height } = this.state

    return (
      <>
        <div
          style={{ opacity: textReady ? 1 : 0, transition: "opacity 1s ease" }}
        >
          <Surface width={width} height={height}>
            <Base
              amplitude={amplitude}
              color={getColor(this.color)}
              aspect={this.state.width / this.state.height}
              radius={this.getRadius()}
            >
              <Lines
                radius={this.getRadius()}
                bgColor={getColor(this.color)}
                len={len}
                aspect={this.state.width / this.state.height}
              >
                <Text
                  size={{ width, height }}
                  title="Stereo Tipo"
                  subtitle="Lemongrass"
                  bgColor={this.color}
                  onReady={this.onTextReady}
                />
              </Lines>
            </Base>
          </Surface>
        </div>
      </>
    )
  }
  static defaultProps = { blue: 0.5 }
}

export default GL
